export const choristes = [
    {
        pupitre: "Sopranes",
        personnes: [
            "Camille Caradeuc",
            "Ludivine Clodong-Berger",
            "Mélanie Gavand",
            "Béatrice Grinfeld",
            "Adèle Hubert",
            "Katelyn Isaacson",
            "Camille Martin",
            "Mathilde Pianfetti",
            "Suzie Ripoche",
            "Alice Widmer",
        ],
    },
    {
        pupitre: "Mezzos",
        personnes: [
            "Sarah Bordes",
            "Zoé Cassard",
            "Luce Courceulles",
            "Céleste Lejeune",
            "Anémone Robic",
            "Marilou Rolland",
            "Magalie Vallin",
            "Claire Violle",
        ],
    },
    {
        pupitre: "Altos",
        personnes: [
            "Malo Cosset-Chéneau",
            "Lucie Damianthe",
            "Etienne Goulot-Martin",
            "Sébastien Goulot-Martin",
            "Ariane Le Fournis",
            "Jeanne Monville",
            "Laure-Alice Poulain",
            "Audrey Vollerin",
        ],
    },
    {
        pupitre: "Ténors",
        personnes: [
            "Aurel Barré",
            "Paul Campana",
            "Antoine Dizier",
            "Quentin Guillon de Princé",
            "Martin Laskawiec",
            "Olivier Merlin",
            "Benoit Morros",
            "Filipe Rodriguez",
            "Oscar Sajous",
        ],
    },
    {
        pupitre: "Basses",
        personnes: [
            "Olivier Bizot",
            "Arthur Bourcigaux",
            "Antonin Boutinaud",
            "Thomas Crouigneau",
            "Nans De Jésus",
            "Milan Faure",
            "Nicolas Forin",
            "Victor Requier",
        ],
    },
]
